import React, { useState } from "react";
import { useStaticQuery, graphql, Link } from "gatsby";
import Fade from "react-reveal/Fade";
import { Icon } from "react-icons-kit";
import { androidMenu } from "react-icons-kit/ionicons/androidMenu";
import { androidClose } from "react-icons-kit/ionicons/androidClose";
import Logo from "@common/src/components/UIElements/Logo";
import Button from "@common/src/components/Button";
import Container from "@common/src/components/UI/Container";
import NavbarWrapper, {
  MenuArea,
  MobileMenu,
  NavDropWrapper,
} from "./navbar.style";
import LogoImage from "@common/src/assets/image/logo-genme.png";

const Navbar = ({ origin }) => {
  const Data = useStaticQuery(graphql`
    query {
      agencyModernJson {
        leftMenuItems {
          label
          path
        }
        rightMenuItems {
          label
          path
        }
        mobileMenuItems {
          label
          path
        }
      }
    }
  `);

  const [mobileMenu, setMobileMenu] = useState(false);

  const scrollItems = [];

  Data.agencyModernJson.leftMenuItems.forEach((item) => {
    scrollItems.push(item.path.slice(1));
  });

  const handleMobileMenu = () => {
    setMobileMenu(!mobileMenu);
  };

  const handleHandleMenuClose = () => {
    setMobileMenu(false);
  };

  return (
    <NavbarWrapper className="agencyModern-navbar navbar">
      <Container>
        <Logo
          href="/"
          logoSrc={LogoImage}
          title="GEN-ME"
          className="main-logo"
        />

        <MenuArea>
          <ul className="menu-items menu-left scrollspy__menu">
            <li>
              <Link
                to={`/`}
                className={`${`Beranda` === origin ? "is-current" : ""}`}
              >
                {`Beranda`}
              </Link>
            </li>
            <li>
              <Link
                to={`/tentang-kami`}
                className={`${`Tentang Kami` === origin ? "is-current" : ""}`}
              >
                {`Tentang Kami`}
              </Link>
            </li>
            <li>
              <NavDropWrapper>
                <Link
                  to={`/produk`}
                  className={`${`Produk` === origin ? "is-current" : ""}`}
                >
                  {`Produk`}
                </Link>
                <div>
                  <Link
                    to={`/produk#produk-nutrigenmelife`}
                  >{`NutriGENME-LIFE`}</Link>
                  {/* <Link to={`/produk#produk-nutrigenme`}>{`NutriGEN-ME`}</Link> */}
                  {/* <Link to={`/produk#produk-skingenme`}>{`SkinGEN-ME`}</Link> */}
                  <Link href="/produk#produk-kiddygenme">{`KiddyGEN-ME`}</Link>
                  <Link href="/produk#produk-primegenme">{`PrimeGEN-ME`}</Link>
                </div>
              </NavDropWrapper>
            </li>
            <li>
              <Link
                to={`/aktivitas`}
                className={`${`Aktivitas` === origin ? "is-current" : ""}`}
              >
                {`Aktivitas`}
              </Link>
            </li>
            <li>
              <Link
                to={`/kontak`}
                className={`${`Kontak` === origin ? "is-current" : ""}`}
              >
                {`Kontak`}
              </Link>
            </li>
          </ul>

          <ul className="menu-items menu-right scrollspy__menu">
            <li>
              <Link
                to={`/faq`}
                className={`${`FAQ` === origin ? "is-current" : ""}`}
              >
                {`FAQ`}
              </Link>
            </li>
          </ul>

          <Button
            className="menubar"
            icon={
              mobileMenu ? (
                <Icon
                  style={{ color: "#02073E" }}
                  className="bar"
                  size={32}
                  icon={androidClose}
                />
              ) : (
                <Fade>
                  <Icon
                    style={{ color: "#02073E" }}
                    className="close"
                    icon={androidMenu}
                    size={32}
                  />
                </Fade>
              )
            }
            color="#0F2137"
            variant="textButton"
            onClick={handleMobileMenu}
          />
        </MenuArea>
      </Container>

      <MobileMenu className={`mobile-menu ${mobileMenu ? "active" : ""}`}>
        <Container>
          <ul className="menu">
            {/* {mobileMenuItems.map((menu, index) => (
              <li key={`menu_key${index}`}>
                <Link to={menu.path} onClick={handleHandleMenuClose}>
                  {menu.label}
                </Link>
              </li>
            ))} */}
            <li>
              <Link
                to={`/`}
                className={`${`Beranda` === origin ? "is-current" : ""}`}
                onClick={handleHandleMenuClose}
              >
                {`Beranda`}
              </Link>
            </li>
            <li>
              <Link
                to={`/tentang-kami`}
                className={`${`Tentang Kami` === origin ? "is-current" : ""}`}
                onClick={handleHandleMenuClose}
              >
                {`Tentang Kami`}
              </Link>
            </li>
            <li>
              <NavDropWrapper>
                <Link
                  to={`/produk`}
                  className={`${`Produk` === origin ? "is-current" : ""}`}
                  onClick={handleHandleMenuClose}
                >
                  {`Produk`}
                </Link>
                <div>
                  <Link
                    to={`/produk#produk-nutrigenme`}
                    onClick={handleHandleMenuClose}
                  >
                    {`NutriGEN-ME`}
                  </Link>
                  <Link
                    to={`/produk#produk-skingenme`}
                    onClick={handleHandleMenuClose}
                  >
                    {`SkinGEN-ME`}
                  </Link>
                  <Link
                    href="/produk#produk-nutrigenmelife"
                    onClick={handleHandleMenuClose}
                  >{`NutriGENME-LIFE`}</Link>
                  <Link
                    href="/produk#produk-nutrigenmelife"
                    onClick={handleHandleMenuClose}
                  >{`KiddyGEN-ME`}</Link>
                  <Link
                    href="/produk#produk-nutrigenmelife"
                    onClick={handleHandleMenuClose}
                  >{`PrimeGEN-ME`}</Link>
                </div>
              </NavDropWrapper>
            </li>
            <li>
              <Link
                to={`/aktivitas`}
                className={`${`Aktivitas` === origin ? "is-current" : ""}`}
                onClick={handleHandleMenuClose}
              >
                {`Aktivitas`}
              </Link>
            </li>
            <li>
              <Link
                to={`/kontak`}
                className={`${`Kontak` === origin ? "is-current" : ""}`}
                onClick={handleHandleMenuClose}
              >
                {`Kontak`}
              </Link>
            </li>
            <li>
              <Link
                to={`/faq`}
                className={`${`FAQ` === origin ? "is-current" : ""}`}
                onClick={handleHandleMenuClose}
              >
                {`FAQ`}
              </Link>
            </li>
          </ul>
        </Container>
      </MobileMenu>
    </NavbarWrapper>
  );
};

export default Navbar;
