import styled from "styled-components";
import { themeGet } from "styled-system";
import navBg from "@common/src/assets/image/agencyModern/nav-bg.png";

const NavbarWrapper = styled.header`
  width: 100%;
  padding: 10px 10px;
  background-color: #fff;
  position: fixed;
  z-index: 9999;
  transition: all 0.3s ease;
  @media only screen and (max-width: 1366px) {
    padding: 20px 0 21px;
  }
  > div.container {
    width: 100%;
    display: flex;
    align-items: center;
  }
  img {
    width: 115px;
  }
`;

export const MenuArea = styled.nav`
  display: flex;
  align-items: center;
  width: 100%;
  overflow: hidden;
  .menubar {
    display: none;
  }
  @media only screen and (max-width: 1024px) {
    justify-content: flex-end;
  }
  @media only screen and (max-width: 768px) {
    .menubar {
      display: block;
    }
  }
  .menu-items {
    display: flex;
    align-items: center;
    margin-right: 11px;
    opacity: 1;
    visibility: visible;
    transition: all 0.3s ease;
    @media only screen and (max-width: 1366px) {
      margin-right: 13px;
    }
    @media only screen and (max-width: 768px) {
      display: none;
    }
    li {
      margin: 0 19px;
      a {
        color: ${themeGet("colors.genmeText")};
        font-family: "DM Sans", sans-serif;
        font-size: 16px;
        font-weight: 600;
        transition: all 0.3s ease;
        &:hover {
          color: ${themeGet("colors.genmePrimary")};
        }
      }
      .is-current {
        /* background: transparent url(${navBg}) no-repeat center bottom / contain; */

        color: ${themeGet("colors.genmePrimary")};
      }
      @media only screen and (max-width: 1366px) {
        margin: 0 17px;
      }
      &:first-child {
        margin-left: 0;
      }
      &:last-child {
        margin-right: 0;
      }
    }
  }
  .menu-right {
    margin-left: auto;
  }
  &.active {
    .menu-items {
      opacity: 0;
      visibility: hidden;
    }
  }
`;

export const NavDropWrapper = styled.div`
  float: left;
  overflow: hidden;
  transition: all 2s ease;

  > div {
    display: none;
    position: absolute;
    background-color: #fff;
    min-width: 160px;
    box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
    z-index: 1;
    transition: all 2s ease;

    a {
      float: none;
      color: black;
      margin: 5px 0;
      padding: 20px 100px 20px 15px;
      text-decoration: none;
      display: block;
      text-align: left;
      font-weight: 500 !important;

      &:hover {
        background-color: #ededed;
      }
    }

    &:hover {
      display: block;
      transition: all 2s ease;
    }
  }

  &:hover {
    > div {
      display: block;
      transition: all 2s ease;
    }
  }

  @media only screen and (max-width: 991px) {
    float: none;

    > div {
      float: none;
      position: relative;
      width: 100%;
      display: block;
      text-align: left;
      box-shadow: none;
      padding-left: 20px;

      a {
        float: none;
        display: block;
        text-align: left;
      }
    }
  }
`;

export const MobileMenu = styled.div`
  display: none;
  @media only screen and (max-width: 991px) {
    display: flex;
    width: 100%;
    height: calc(100vh - 70px);
    padding: 27px 0 40px;
    opacity: 0;
    visibility: hidden;
    position: absolute;
    top: 82px;
    flex-direction: column;
    background-color: ${themeGet("colors.white", "#ffffff")};
    transition: all 0.3s ease;
    color: ${themeGet("colors.secondary", "#000")};
    &.active {
      opacity: 1;
      visibility: visible;
      box-shadow: 0 3px 12px
        ${themeGet("colors.shadow", "rgba(38, 78, 118, 0.1)")};
    }
    .container {
      width: 100%;
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
    }
    ul {
      padding-bottom: 20px;
      li {
        a {
          display: block;
          padding: 13px 0;
          border-radius: 5px;
          transition: all 0.3s ease;
          color: ${themeGet("colors.genmeText")};
          font-weight: 600;
        }
        &:hover {
          a {
            padding: 13px 15px;
            color: ${themeGet("colors.genmePrimary")};
          }
        }
      }
    }
    .reusecore__button {
      width: 100%;
      border-radius: 4px;
      background-image: -moz-linear-gradient(
        -31deg,
        rgb(64, 219, 216) 0%,
        rgb(44, 31, 132) 100%
      );
      background-image: -webkit-linear-gradient(
        -31deg,
        rgb(64, 219, 216) 0%,
        rgb(44, 31, 132) 100%
      );
      background-image: -ms-linear-gradient(
        -31deg,
        rgb(64, 219, 216) 0%,
        rgb(44, 31, 132) 100%
      );
      @media only screen and (max-width: 480px) {
        margin-top: 20px;
      }
    }
  }
`;

export default NavbarWrapper;
