import React from "react";
import { useStaticQuery, graphql } from "gatsby";
import Fade from "react-reveal/Fade";

import Container from "@common/src/components/UI/Container";
import Heading from "@common/src/components/Heading";
import Image from "@common/src/components/Image";
import Link from "@common/src/components/Link";
import Text from "@common/src/components/Text";

import FooterWrapper, {
  FooterInner,
  CopyrightInfo,
  FooterWidget,
  Nav,
  FooterBottom,
  SocialLinks,
} from "./footer.style";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import { whatsapp } from "react-icons-kit/icomoon/whatsapp";
import { envelopeO } from "react-icons-kit/fa/envelopeO";
import { angleRight } from "react-icons-kit/fa/angleRight";
import Icon from "react-icons-kit";

const Footer = () => {
  const Data = useStaticQuery(graphql`
    query {
      genmeLogo: file(relativePath: { eq: "image/logo-genme.png" }) {
        childImageSharp {
          fluid(quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      BALogo: file(relativePath: { eq: "image/logo_innolab.png" }) {
        childImageSharp {
          fluid(quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      agencyModernJson {
        aboutUs {
          id
          title
        }
        ourInformation {
          id
          title
        }
        myAccount {
          id
          title
        }
        social {
          id
          title
          icon {
            childImageSharp {
              fluid(quality: 100) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      }
      eCommerce: allContentfulECommerce(sort: { fields: title, order: DESC }) {
        nodes {
          title
          image {
            gatsbyImageData(height: 50)
          }
          url
        }
      }
    }
  `);

  return (
    <FooterWrapper>
      <Container>
        <FooterInner>
          <CopyrightInfo>
            <Fade up delay={100}>
              <Image
                src={Data.genmeLogo.childImageSharp.fluid.src}
                alt="Logo"
                className="logo-genme"
              />
              <Image
                src={Data.BALogo.childImageSharp.fluid.src}
                alt="Logo Kalgen Innolab"
                className="logo-Innolab"
              />
            </Fade>
          </CopyrightInfo>

          <FooterWidget>
            <Fade up delay={400}>
              <Heading as="h4" content="Tentang Kami" />
              <Nav>
                <Link href="/tentang-kami/#profil-perusahaan">{`Profil Perusahaan`}</Link>
                <Link href="/tentang-kami/#akurasi-perusahaan">{`Akurasi`}</Link>
                <Link href="/tentang-kami/#pakar-ahli-perusahaan">{`Teknologi`}</Link>
                <Link href="/tentang-kami/#privasi-perusahaan">{`Privasi`}</Link>
                {/* <Link href="/tentang-kami#pelayanan-perusahaan">{`Pelayanan`}</Link> */}
              </Nav>
            </Fade>
          </FooterWidget>

          <FooterWidget>
            <Fade up delay={200}>
              <Heading as="h4" content="Produk" />
              <Nav>
                {/* <Link href="/produk/#produk-skingenme">{`SkinGEN-ME`}</Link> */}
                <Link href="/produk/#produk-nutrigenmelife">{`NutriGENME-LIFE`}</Link>
                <Link href="/produk/#produk-kiddygenme">{`KiddyGEN-ME`}</Link>
                <Link href="/produk/#produk-primegenme">{`PrimeGEN-ME`}</Link>
              </Nav>
            </Fade>
          </FooterWidget>

          <FooterWidget>
            <Fade up delay={300}>
              <Heading as="h4" content="Aktivitas" />
              <Nav>
                <Link href="/aktivitas/#team">{`Testimoni`}</Link>
                <Link href="/aktivitas/#news">{`Artikel`}</Link>
                <Link href="/aktivitas/#events">{`Acara`}</Link>
                <Link href="/aktivitas/#video">{`Video`}</Link>
              </Nav>
            </Fade>
          </FooterWidget>

          <FooterWidget>
            <Fade up delay={500}>
              <Heading as="h4" content="Kontak" />
              <Nav>
                <SocialLinks>
                  <Link 
                    href="https://wa.me/+6285281112343?text=Halo%20GENME%20%21%0AMau%20tanya%20info%20mengenai%20tes%20genetik%20lebih%20lanjut.%20Terima%20kasih" 
                    target="_blank"
                    rel="noopener noreferrer”"
                  >
                    <Icon icon={whatsapp} size={23} />
                    {`+62 852-8111-2343`}
                  </Link>
                </SocialLinks>
                <SocialLinks>
                  <Link
                    href="mailto:info@genme.id"
                    target="_blank"
                    rel="noopener noreferrer”"
                  >
                    <Icon icon={envelopeO} size={23} />
                    Click this to mail us
                  </Link>
                </SocialLinks>
                <SocialLinks>
                  <Link href="/kontak">
                    {`Platform lainnya`} <Icon icon={angleRight} size={18} />
                  </Link>
                </SocialLinks>
                <Link href="/faq">{`FAQ`}</Link>
              </Nav>
            </Fade>
          </FooterWidget>
        </FooterInner>
      </Container>
      <FooterBottom>
        <FooterWidget>
          <Fade left delay={500}>
            <div style={{ fontSize: "15px" }}>
              <Text
                content={`KALGen INNOLAB
                Jl. Jenderal Ahmad Yani No.2, Kayu Putih, Kec. Pulo Gadung, Kota Jakarta Timur, Daerah Khusus Ibukota Jakarta 13210`}
              />
            </div>
          </Fade>
        </FooterWidget>

        <FooterWidget>
          <Fade right delay={600}>
            <Text content={`Temukan kami di e-commerce: `} />
            {Data.eCommerce.nodes.map((eCom, index) => (
              <Link href={eCom.url} key={`eComLogo-${index}`}>
                <GatsbyImage
                  className="eCom-logo"
                  image={getImage(eCom.image)}
                  alt={eCom.title}
                />
              </Link>
            ))}
          </Fade>
        </FooterWidget>
      </FooterBottom>
    </FooterWrapper>
  );
};

export default Footer;
