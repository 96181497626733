import React from 'react';
import PropTypes from 'prop-types';
import { StaticQuery, graphql } from 'gatsby';
// import maintenanceImage from "@common/src/assets/image/maintenance.jpg"

const Layout = ({ children }) => (
  <StaticQuery
    query={graphql`
      query SiteTitleQuery {
        site {
          siteMetadata {
            title
          }
        }
      }
    `}
    render={data => (
      // Maintenance Page
      // <div style={{position: 'relative', width: '100%', minHeight: '100vh',}}>
      //   <div style={{width: '100%', height: '100%', position: 'absolute', top: 0, left: 0, backgroundColor: 'white', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
      //       <img src={maintenanceImage} alt="" style={{width: '100%', maxWidth:'640px'}}/>
      //   </div>
      // </div>
      <main>{children}</main>
    )}
  />
);

Layout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Layout;
