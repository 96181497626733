import styled from "styled-components";
import { themeGet } from "styled-system";
import { rgba } from "polished";

const FooterWrapper = styled.footer`
  padding: 75px 0 0 0;
  border-top: 2px solid rgba(116, 120, 125, 0.3);
`;

export const FooterInner = styled.div`
  display: flex;
  @media only screen and (max-width: 991px) {
    flex-wrap: wrap;
  }
  > div {
    width: calc(100% / 5);
    @media only screen and (max-width: 991px) {
      width: calc(100% / 4);
      &:nth-child(1) {
        margin-right: 100px;
      }
      &:nth-child(2),
      &:nth-child(4) {
        margin-right: 70px;
      }
      &:nth-child(4),
      &:nth-child(5) {
        margin-top: 50px;
      }
    }
    @media only screen and (max-width: 768px) {
      &:nth-child(1) {
        margin-right: 60px;
      }
      &:nth-child(2),
      &:nth-child(4) {
        margin-right: 30px;
      }
      margin-right: 20px;
    }
    @media only screen and (max-width: 600px) {
      width: calc(100% / 2);
      margin-right: 0 !important;
      &:nth-child(1) {
        margin-top: 0;
      }

      &:nth-child(2),
      &:nth-child(3),
      &:nth-child(4),
      &:nth-child(5) {
        margin-top: 30px;
      }
    }
    @media only screen and (max-width: 480px) {
      width: 100%;
      margin-right: 0 !important;
    }
    @media only screen and (width: 320px) {
      margin-top: 4rem !important;
    }
  }
  .logo-genme {
    height: 100px;
  }
  .logo-Innolab {
    height: 170px;
    margin-top: 0 !important;
  }
`;

export const CopyrightInfo = styled.div`
  margin-right: 70px;
  .react-reveal:nth-of-type(2) img {
    margin-top: 50px;
  }
  p {
    font-size: 14px;
    line-height: 18px;
    margin-top: 24px;
    a {
      color: ${themeGet("colors.headingColor")};
    }
  }
  .copyright {
    color: ${rgba("#0f2137", 0.6)};
    margin-top: 20px;
  }
`;

export const FooterWidget = styled.div`
  h4 {
    font-family: "DM Sans", sans-serif;
    letter-spacing: -0.5px;
    color: ${themeGet("colors.headingColor")};
    font-weight: 500;
    font-size: 18px;
    line-height: 30px;
  }
`;

export const Nav = styled.nav`
  a {
    color: ${rgba("#02073E", 0.8)};
    display: flex;
    align-items: center;
    font-size: 15px;
    line-height: 2.5;
    transition: 0.3s ease 0s;
    img {
      margin-right: 12px;
    }
    &:hover {
      color: ${themeGet("colors.primary")};
    }
  }
`;

export const FooterBottom = styled.nav`
  display: flex;
  justify-content: flex-end;
  margin: 50px 0 0 calc(100% / 8);
  @media only screen and (max-width: 1300px) {
    margin: 50px 0 0 calc(100% / 15);
    a {
      margin: 5px 0;
    }
  }
  @media only screen and (max-width: 991px) {
    margin: 50px 0 0 calc(100% / 20);
    flex-direction: column;
  }
  @media only screen and (max-width: 600px) {
    margin: 50px 0 0 0;
    flex-direction: column;
  }

  > div {
    height: 150px;
    &:nth-child(1) {
      width: calc(100% / 3);
      padding-right: 50px;
      display: flex;
      align-items: flex-start;

      @media only screen and (min-width: 1900px) {
        padding-left: 160px;
      }
    }
    &:nth-child(2) {
      display: flex;
      align-items: center;
      width: 100%;
      background-color: ${themeGet("colors.genmeLight")};
      border-radius: 15% / 95% 0 0 0;
      > div {
        > p {
          margin: 0 10px 0 80px;
          font-weight: 600;
        }
        .eCom-logo {
          margin: 0 10px;
        }

        @media only screen and (max-width: 1300px) {
          &:nth-child(1) {
            width: 30%;
          }
          > p {
            margin: 0 10px 0 40px;
            font-weight: 600;
          }
          .eCom-logo {
            margin: 0 10px;
          }
        }
        @media only screen and (max-width: 991px) {
          &:nth-child(1) {
            width: 30%;
          }
          > p {
            margin: 0 20px 0 40px;
            font-weight: 600;
          }
          .eCom-logo {
            margin: 0 10px;
          }
        }
        @media only screen and (max-width: 600px) {
          &:nth-child(1) {
            width: 90%;
          }
          > p {
            text-align: center;
            font-weight: 600;
            font-size: 16px;
          }
          .eCom-logo {
            margin: 0;
          }
        }
      }
    }
    @media only screen and (max-width: 1300px) {
      width: calc(100% / 4);
      &:nth-child(1) {
        margin-right: 0;
      }
    }
    @media only screen and (max-width: 991px) {
      width: 100%;

      margin: 0;
      &:nth-child(1) {
        margin-right: 0;
        height: 100px;
      }
      &:nth-child(2),
      &:nth-child(4) {
        margin-right: 70px;
      }
      &:nth-child(4),
      &:nth-child(5) {
        margin-top: 50px;
      }
    }
    @media only screen and (max-width: 768px) {
      width: 100%;
      &:nth-child(1) {
        margin-right: 60px;
        width: 100%;
      }
      &:nth-child(2),
      &:nth-child(4) {
        margin-right: 30px;
      }
      margin-right: 20px;
    }
    @media only screen and (max-width: 600px) {
      width: calc(100% / 2);
      margin-right: 0 !important;
      justify-content: center;
      &:nth-child(1) {
        padding-left: 30px;
        margin-right: 60px;
        width: 100%;
      }
      &:nth-child(2) {
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        height: 300px;
        border-radius: 30px 30px 0 0;
      }
    }
    @media only screen and (max-width: 480px) {
      width: 100%;
      margin-right: 0 !important;
    }
    @media only screen and (width: 320px) {
      margin-top: 2rem !important;
      margin: 0px 0 0;
    }
  }
`;

export const SocialLinks = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  a {
    i {
      margin-right: 10px;
    }
  }
  &:nth-of-type(3) {
    i {
      margin-left: 30px;
    }
  }
`;

export default FooterWrapper;
